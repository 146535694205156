@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: url('./montserrat.ttf');
}

:root {
  height: 100%;
}

body {
  margin: 0;
  font-family: montserrat;
  color: #EFF3FB;
  height: 100%;
  background-color: #050607;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-luminous-gradient-line {
  height: 100%;
  background: conic-gradient(from 90deg at 40% 50%, #F8F8F8 0deg, #050607 0.04deg, #050607 245deg, #F8F8F8 359deg);
}

.bg-gradient-line {
  height: 100%;
  background: conic-gradient(from 90deg at 40% 50%, #F8F8F8 0deg, #050607 0.04deg, #050607 358.13deg, #F8F8F8 360deg);
}

.flipped {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.gradient-test {
  background: radial-gradient(58.12% 110.04% at 46.74% 110.04%, rgba(143, 191, 253, 0.5) 0%, rgba(38, 72, 122, 0.6) 29.63%, rgba(15, 18, 24, 0) 100%);
  opacity: 0.35;
}

.gradient-elipse-5 {
  background: radial-gradient(62.65% 46.71% at 74.07% 12.71%, rgba(143, 191, 253, 0.5) 0%, rgba(38, 72, 122, 0.6) 29.63%, rgba(15, 18, 24, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.5;
}